import React from "react";
import FreamScroll from "../../shared/fream-scroll";

function TitleSection(props) {
  const { extraClass, title, children } = props;
  return (
    <div className={"row typography section-title " + extraClass}>
      <FreamScroll
        className="large-12 columns text-align-center"
        queueClass="anim"
        duration={1}
        queueDuration={0.5}
        offset={-500}
      >
        <h1 className="anim">
          {title}
        </h1>
        <div>
          {children}
        </div>
      </FreamScroll>
    </div>
  );
}

export default TitleSection;
