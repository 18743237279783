import React, { Component } from 'react';
import { translate } from 'react-i18next';
import Spinner from 'components/spinner';

class Redirect3ds extends Component {
  constructor(props) {
    super(props);
  }
  componentDidMount() {
    var event = new CustomEvent('autenticationEvent', { detail: window.location.search });
    window.parent.document.dispatchEvent(event);
  }

  render() {
    console.log('test');
    const { t } = this.props;
    return (
      <div>
        {/*{t('common.processing')}*/}
        {/*<Spinner style={{width: 100, height: 100, borderLeftColor: 'gray', marginTop: '30%' }} />*/}
      </div>
    );
  }
}

export default translate('translations')(Redirect3ds);
