import React, { Component } from 'react';
import { compose } from 'redux';
import { translate } from "react-i18next";
import withStyles from '@material-ui/core/styles/withStyles';
import { NavBarStyles } from 'components/navBar';
import Iframe from 'react-iframe';
import classNames from 'classnames';
import i18next from 'i18next';

const langPl = i18next.language.includes('pl');

let staticFileAlias = '';
if (!window.location.hostname.includes('localhost') && !window.location.hostname.includes('gamma.evryplace')) {
  staticFileAlias = 'home/';
}

const styles = theme => ({
  root: {
    marginTop: 96,
    backgroundImage: 'url(/static/home/confirmation/bg.png)',
    paddingTop: 80,
    paddingBottom: 80,
  },
  container: {
    backgroundColor: 'white',
    borderRadius: 24,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: '0 auto',
    maxWidth: 1280,
    padding: '64px 33px 80px 33px'
  },
  logoRow: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  logo: {
    backgroundImage: 'url(/static/home/confirmation/logo.png)',
    backgroundRepeat: 'noRepeat',
    width: 114,
    height: 80,
  },
  title: {
    fontSize: 30,
    fontWeight: 500,
    marginTop: 20,
    textAlign: 'center'
  },
  contentRow: {
    display: 'flex',
    flexDirection: 'row',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      alignItems: 'center'
    },
  },
  leftCol: {
    maxWidth: 400,
    marginTop: 100,
    [theme.breakpoints.down('md')]: {
      maxWidth: 655,
      textAlign: 'center',
      marginTop: 50,
    },
    [theme.breakpoints.down('sm')]: {
      maxWidth: '90vw',
    },
  },
  rightCol: {
    marginTop: -20,
  },
  p1: {
    fontSize: 13,
    color: '#666666',
    marginBottom: 24,
  },
  p2: {
    fontSize: 16,
    color: 'black',
    marginBottom: 24,
  },
  p3: {
    fontSize: 13,
    color: '#666666',
    marginBottom: 16,
    textAlign: 'center'
  },
  h3: {
    fontWeight: 500,
    marginBottom: 24,
  },
  button1: {
    backgroundColor: '#ffff00',
    borderRadius: 6,
    width: 300,
    height: 41,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '0 auto',
    marginBottom: 16,
  },
  button2: {
    border: '2px solid #008aae',
    borderRadius: 6,
    width: 300,
    height: 41,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '0 auto',
    marginBottom: 16,
  },
  buttonText: {
    color: '#008aae',
    fontSize: 15,
  }
});

class Confirmation extends Component{
  constructor(props){
    super(props);
  }
  componentDidMount() {
    this.props.navBar.setStyle(NavBarStyles.dark);
  }
  componentWillUnmount() {
    this.props.navBar.setStyle();
  }
  render(){
    const { t, classes } = this.props;

    return (
      <div className={classes.root}>
        <div className={classes.container}>
          <div className={classes.logoRow}>
            <div className={classes.logo} />
            <div className={classes.title}>{t('confirmation.title')}</div>
          </div>
          <div className={classes.contentRow}>
            <div className={classes.leftCol}>
              <p className={classes.p1}>{t('confirmation.p1')}</p>
              <h3 className={classes.h3}>{t('confirmation.h1')}</h3>
              <p className={classes.p2}>{t('confirmation.p2')}</p>
              <p className={classes.p3}>{t('confirmation.p3')}</p>
              <div className={classes.button1}>
                <a className={classes.buttonText} href="/panel/#/projects">{t('confirmation.b1')}</a>
              </div>
              <div className={classes.button2}>
                <a className={classes.buttonText} href={langPl ? 'https://evryplace.com/pl/knowledge-base' : 'https://evryplace.com/knowledge-base'} target="_blank">{t('confirmation.b2')}</a>
              </div>
            </div>
            <div className={classes.rightCol}>
              <Iframe url={`${staticFileAlias}static/calendly/calendly.html`} frameBorder={0} height="1030px" width="785px" />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default compose(
  withStyles(styles),
  translate("translations")
)(Confirmation);
