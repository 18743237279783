import { parseLogic } from './services/redux-commons';
import { logic as exploreLogic } from './shared/redux/presentations';
import { logic as authLogic } from './shared/redux/auth';
import { logic as cmsLogic } from './shared/redux/cms';
import { logic as presentationLogic } from './shared/redux/presentation';

export default parseLogic({
	...exploreLogic,
  ...authLogic,
  ...presentationLogic,
  ...cmsLogic
});
