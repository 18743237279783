import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import AssetHelper, { ImageVariants } from 'AssetHelper';
import { translate } from "react-i18next";
import { compose } from 'redux';
import PropTypes from 'prop-types';

const styles = {
    SplashPage: {
        position: 'fixed',
        zIndex: 200,
        height: '100%',
        width: '100%',
        backgroundColor: 'rgba(13, 13, 13, 0.45)',
        top: 0,
    },
    splashContainer: {
        fontSize: '48px',
        top: '20%',
        position: 'fixed',
        height: '80%',
        textAlign: 'center'
    },
    logo360: {
        width: '182px',
        height: '182px',
        cursor: 'pointer',
        marginTop: '20px'
    },
    mouseImg: {
        height: '66px',
        width: '36px'
    },
    actionText: {
        fontSize: '18px'
    },
    poweredBy: {
        fontSize: '14px'
    },
    '@media (max-width: 1024px)': {
        splashContainer: {
            fontSize: '36px'
        },
        logo360: {
            width: '160px',
            height: '160px'
        },
        mouseImg: {
            height: '66px',
            width: '36px'
        },
        actionText: {
            fontSize: '16px'
        },
        poweredBy: {
            fontSize: '12px'
        }
    },
    '@media (max-width: 640px)': {
        splashContainer: {
            fontSize: '28px'
        },
        logo360: {
            width: '120px',
            height: '120px'
        },
        mouseImg: {
            height: '46px',
            width: '26px'
        },
        actionText: {
            fontSize: '16px'
        },
        poweredBy: {
            fontSize: '12px'
        }
    },
    '@media (max-height: 640px)': {
        splashContainer: {
            fontSize: '36px'
        },
        logo360: {
            width: '120px',
            height: '120px'
        },
        mouseImg: {
            display: 'none'
        },
        actionText: {
            display: 'none'
        },
        poweredBy: {
            display: 'none'
        }
    }
}

const SplashPage = ({ classes, t , presentation, ...props}) => {
    let presentationName = '';

    if (presentation) {
        if(Object.keys(presentation).length) {
            presentationName = presentation.name
        }
    } else {
        presentationName = t('embed.loading');
    }

    return(
        <Grid container direction='column' justify='space-around' className={classes.SplashPage} {...props}>
            <Grid container direction='column' justify='space-around' alignItems='center' className={classes.splashContainer}>
                <Grid item>{presentationName}</Grid>
                <Grid item><img className={classes.logo360} src={AssetHelper.staticPath('/static/home/embed/embed_360_1.png')} /></Grid>
                <Grid item><img className={classes.mouseImg} src={AssetHelper.staticPath('/static/home/embed/embed_mouse1.png')} /></Grid>
                <Grid item className={classes.actionText}>{t('embed.splashPageText')}</Grid>
                <Grid container justify='center' alignItems='center' direction='column' className={classes.poweredBy}>
                    <Grid item>Powered by</Grid>
                    <Grid item><img src={AssetHelper.staticPath('/static/home/embed/embed_powered_by_evryplace.png')}/></Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

SplashPage.propTypes = {
    classes: PropTypes.shape({}).isRequired,
    t: PropTypes.shape({}).isRequired,
    presentation: PropTypes.shape({
        name: PropTypes.string
    }),
}

SplashPage.defaultProps = {
    presentation: {
        name: ''
    }
}

export default compose(
    withStyles(styles),
    translate('translations')
)(SplashPage)
