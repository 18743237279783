import React, { Component } from 'react';
import './index.scss';
import VRModal from 'components/VRModal/';
import OwlCarousel from "react-owl-carousel2";
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import MetaTags from 'react-meta-tags';
import IconArrow from 'static/home/icons/arrow-small.svg';
import IconPause from 'static/home/presentation/pause-icon-white.svg';
import IconAutoplay from 'static/home/presentation/autoplay-icon-white.svg';
import IconInfo from 'static/home/presentation/info-icon-white.svg';
import IconVr from 'static/home/presentation/vr-icon-white.svg';
import IconFullscreen from 'static/home/presentation/fullscreen-icon-white.svg';
import IconLayers from 'static/home/presentation/layers-icon-white.svg';
import IconAdd from '@material-ui/icons/Add';
import IconGyroscope from 'static/home/presentation/gyroscope.svg';
import IconGyroscopeOff from 'static/home/presentation/gyroscope-off.svg';
import IconRemove from '@material-ui/icons/Remove';
import { PlayerContext } from 'components/player/index.jsx';
import IconViews from 'static/home/icons/views.svg';
import Classnames from 'classnames';
import AssetHelper, { ImageVariants } from 'AssetHelper';
import { translate } from "react-i18next";
import SplashPage from '../components/splashPage';
import PrivtePresentationOverlay from '../../../components/privatePresentationOverlay/index';
import SpaceplansModal from 'components/spaceplansModal';
import Slider from 'rc-slider';
import i18next from "i18next";

const isIE11 = !!window.MSInputMethodContext && !!document.documentMode;

let presentationHash;
class Embed extends Component {
  constructor(props) {
    super(props);
    this.state = {
      presenting: false,
      autoplay: false,
      fullscreenActive: false,
      mapsVisible: false,
      infoVisible: false,
      splashScreenVisible: !(location.search.indexOf('autoplay') > -1),
      VRModalOpened: false,
      startPosition: 0,
      sceneName: null,
      sceneId: null,
      presentation: {},
      mousePngVisibility: true,
      spaceplansOpened: false,
      currentSpaceplan: 0,
      zoom: 1,
      isGyroscopeEnabled: null,
      spaceplansKey: false,
    };

    this.props.updateUi({navBarVisible: false});
    this.props.updateUi({footerVisible: false});

    this.player = null; // player instance

    this._onChangeScene = this._onChangeScene.bind(this);
    this._onChangePresentation = this._onChangePresentation.bind(this);
    this.changeScene = this.changeScene.bind(this);
    this.handleKeyboard = this.handleKeyboard.bind(this);
    this.handleAutoplayStart = this.handleAutoplayStart.bind(this);
    this.handleAutoplayStop = this.handleAutoplayStop.bind(this);
    this.toggleContent = this.toggleContent.bind(this);
    this.toggleInfo = this.toggleInfo.bind(this);
    this.toggleFullscreen = this.toggleFullscreen.bind(this);
    this.onFullscreenChange = this.onFullscreenChange.bind(this);
    this.toggleVR = this.toggleVR.bind(this);
    this.toggleQRModal = this.toggleQRModal.bind(this);
    this.loadEmbed = this.loadEmbed.bind(this);
    this.toggleAutoplay = this.toggleAutoplay.bind(this);
    this.updateCarouselPosition = this.updateCarouselPosition.bind(this);
    this.prevScene = this.prevScene.bind(this);
    this.nextScene = this.nextScene.bind(this);
    this.setMousePngVisibility = this.setMousePngVisibility.bind(this);
    this.setPlanIndex = this.setPlanIndex.bind(this);
    this.toggleSpaceplansModal = this.toggleSpaceplansModal.bind(this);
    this.toggleSpaceplansKey = this.toggleSpaceplansKey.bind(this);
    this.zoomOut = this.zoomOut.bind(this);
    this.zoomIn = this.zoomIn.bind(this);
    this.enableGyroscope = this.enableGyroscope.bind(this);
    this.disableGyroscope = this.disableGyroscope.bind(this);
    window.addEventListener("fullscreenchange", this.onFullscreenChange);
    window.addEventListener("mozfullscreenchange", this.onFullscreenChange);
    window.addEventListener("webkitfullscreenchange", this.onFullscreenChange);

    if (!this.props.presentation && this.props.match && this.props.match.params) {
      presentationHash = this.props.match.params.hash;

      if(presentationHash && presentationHash.indexOf('-') > -1) {
        presentationHash = presentationHash.slice(0, presentationHash.indexOf('-'));
      }

      this.props.fetchPresentation({hash: presentationHash});
    }
  }
  componentWillReceiveProps(nextProps) {
    if (!nextProps.isLoading && !nextProps.isFound && !nextProps.passwordRequested) {
      this.props.history.push(i18next.language.includes('pl') ? '/static/embed-not-found/404-pl.html' : '/static/embed-not-found/404-en.html');
      window.location.reload();
    }
    if (!this.player && nextProps.player.instance) {
      this.player = nextProps.player.instance;

      this.player.getInstance().on('changeScene', this._onChangeScene);
      this.player.getInstance().on('changePresentation', this._onChangePresentation);

      this.player.getInstance().on('autoplay.start', this.handleAutoplayStart);
      this.player.getInstance().on('autoplay.stop', this.handleAutoplayStop);

      this.player.disableOverlay();
    }
    if (this.props.presentationMeta && this.props.presentationMeta.thumbnail) {
      this.player.setThumbnailHash(this.props.presentationMeta.thumbnail.hash);
    }

    if (nextProps.presentationMeta && nextProps.presentationMeta.thumbnail) {
      this.player.setThumbnailHash(nextProps.presentationMeta.thumbnail.hash);
    }

    const { player } = this.props;
    const { instance } = player || {};
    if(instance) {
      this.setState({
        presentation: instance.props.presentation
      });
    }

    if (nextProps.presentationMeta && nextProps.presentationMeta.hash && nextProps.match.params.hash && nextProps.presentationMeta.hash !== nextProps.match.params.hash) {
      const hash = nextProps.match.params.hash;

      this.props.fetchPresentation({ hash });
    }

    this.player.isGyroscopeEnabled()
      .then(val => {
        this.setState({ isGyroscopeEnabled: val })
      });

      setTimeout(() => {
        if (this.props.presentation && this.props.presentation.id && window.location.search && window.location.search.includes('?autoplay')) {
          this.loadEmbed();
        }
      }, 2000);

    if (location.search.indexOf('autoplay') > -1) {
      this.player.startAutoplay();
    }
  }
  componentDidMount() {
    this.props.navBar.hide();
    if (window.isJivoLoaded) {
      window.document.getElementsByClassName('globalClass_ET')[0].style.display = "none";
    } else {
      window.shouldHideJivo = true;
    }

    window.addEventListener('keyup', this.handleKeyboard);
  }
  componentWillUnmount() {
    this.player.enableRotateScene();
    this.player.enableOverlay();
    this.player.getInstance().off('changeScene', this._onChangeScene);
    this.player.getInstance().off('changePresentation', this._onChangePresentation);
    this.player.getInstance().off('autoplay.start', this.handleAutoplayStart);
    this.player.getInstance().off('autoplay.stop', this.handleAutoplayStop);
    this.props.updateUi({navBarVisible: true});
    this.props.updateUi({footerVisible: true});
    window.removeEventListener("fullscreenchange", this.onFullscreenChange);
    window.removeEventListener("mozfullscreenchange", this.onFullscreenChange);
    window.removeEventListener("webkitfullscreenchange", this.onFullscreenChange);
    window.removeEventListener('keyup', this.handleKeyboard);
    this.props.clearPresentation();
    this.props.navBar.show();
    if (window.isJivoLoaded) {
      window.document.getElementsByClassName('globalClass_ET')[0].style.display = "inline";
    }
  }
  _onChangeScene(e) {
    const { spaceplansOpened } = this.state;
    this.setState({
      sceneName: e.sceneName,
      sceneId: e.sceneId,
    });
    if(spaceplansOpened) {
      this.toggleSpaceplansModal();
    }
  }
  _onChangePresentation(e) {
    const { hash, presentation } = e;
    const { history } = this.props;

    if (presentation) {
      history.push(`/p/${hash}`);
      history.go();
    } else {
      history.push(`/embed/${hash}`);
      history.go();
    }
  }
  enableGyroscope() {
    this.player.enableGyroscope()
      .then(() => {
        this.setState({ isGyroscopeEnabled: true });
        this.player.stopAutoplay();
      });
  }
  disableGyroscope() {
    this.player.disableGyroscope()
      .then(() => this.setState({ isGyroscopeEnabled: false }));
  }
  handleAutoplayStart() {
    this.setState({ autoplay: true });
  }
  handleAutoplayStop() {
    this.setState({ autoplay: false });
  }
  handleKeyboard(e) {
    if (e.code == 'ArrowLeft') {
      this.handleAutoplayStop();
      this.prevScene();
    }
    if (e.code == 'ArrowRight') {
      this.handleAutoplayStop();
      this.nextScene();
    }
  }

  toggleSpaceplansModal() {
    const { spaceplansOpened } = this.state;
    this.setState(state => ({
        ...state,
        spaceplansOpened: !spaceplansOpened,
      }));
  }
  toggleContent() {
    this.setState({
      infoVisible: false,
      mapsVisible: !this.state.mapsVisible
    });
  }
  toggleInfo() {
    this.setState({
      mapsVisible: false,
      infoVisible: !this.state.infoVisible
    });
  }
  onFullscreenChange() {
    this.setState({
      fullscreenActive: !!(document.fullscreenElement || document.mozFullScreenElement || document.webkitFullscreenElement)
    });
  }
  toggleFullscreen() {
    function requestFullscreen() {
      let elem = document.documentElement;

      if (elem.requestFullscreen) {
        elem.requestFullscreen();
      } else if (elem.msRequestFullscreen) {
        elem.msRequestFullscreen();
      } else if (elem.mozRequestFullScreen) {
        elem.mozRequestFullScreen();
      } else if (elem.webkitRequestFullscreen) {
        elem.webkitRequestFullscreen();
      }
    }

    function exitFullscreen() {
      if(document.exitFullscreen) {
        document.exitFullscreen();
      } else if(document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if(document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      }
    }

    if (this.state.fullscreenActive) {
      exitFullscreen();
    } else {
      requestFullscreen();
    }
  }
  toggleVR() {
    if (this.player) {
      this.props.player.isVRCompatible().then(() => {
        this.props.player.toggleVRMode();
      },this.toggleQRModal);
    } else {
      this.toggleQRModal();
    }
  }
  toggleQRModal() {
    this.setState({
      VRModalOpened: !this.state.VRModalOpened
    });
  }
  changeScene(sceneId) {
    if (this.player) {
      this.player.changeScene({sceneId});
    }
  }
  setPlanIndex(planIndex) {
    this.setState({
      currentSpaceplan: planIndex,
    })
  }
  loadEmbed() {
    let { presentation, presentationMeta } = this.props;

    if (!presentation) return;

    this.setState({
      splashScreenVisible: false
    });

    this.props.increasePresentationViewCount({hash: presentationMeta.hash})

    this.player.load(presentation, presentationMeta);
  }
  toggleAutoplay() {
    if (this.state.autoplay) {
      this.player.stopAutoplay();
    } else {
      this.player.startAutoplay();
      this.state.isGyroscopeEnabled && this.disableGyroscope();
    }
  }
  updateCarouselPosition(object) {
    if (object.item.index != this.state.startPosition) {
      this.setState({
        startPosition: object.item.index,
      });
    }
  }
  prevScene() {
    if (this.player) {
      this.player.prevScene();
    }
  }
  nextScene() {
    if (this.player) {
      this.player.nextScene();
    }
  }
  setMousePngVisibility(mousePngVisibility) {
    this.setState({mousePngVisibility});
  }
  changeZoom(newZoom) {
    this.player.changeZoom(newZoom);
  }
  zoomIn() {
    this.player.zoomIn();
  }
  zoomOut() {
    this.player.zoomOut();
  }
  toggleSpaceplansKey() {
    this.setState({ spaceplansKey: !this.state.spaceplansKey })
  }
  render() {
    const { presentationMeta, t, passwordRequested, fetchPresentation, error } = this.props,
      { sceneName, sceneId, spaceplansOpened, currentSpaceplan, zoom, isGyroscopeEnabled } = this.state;

    const iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

    let shareUrl = presentationMeta && location.protocol + '//' + location.host + '/p/' + presentationMeta.hash,
      splashScreenVisible = this.state.splashScreenVisible,
      menuButtonVisible = false,
      autoplayVisible = false,
      presentationName,
      contentTitle,
      mapsContainerVisible = false,
      presentation = this.props.presentation,
      meta = this.props.presentationMeta,
      scenes;

    let publicationDate = '';
    if (meta && meta.publicationDate) {
      let date = new Date(meta.publicationDate);
      publicationDate = [date.getDate(), date.getMonth() + 1, date.getFullYear()].join('-');
    }

    if (presentation && (
      (presentation.spaceplans &&
        presentation.spaceplans.length ) || (
        presentation.menuScene &&
        presentation.menuScene.sceneId
      ))) {
      menuButtonVisible = true;
    }

    if (presentationMeta && Object.keys(presentationMeta).length > 0) {
      presentationName = presentation.name;

      if (sceneName) {
        presentationName += ' | ' + sceneName;
      }

      if (meta && meta.owner && meta.owner.avatar) {
        contentTitle = (<div className={isIE11 ? "title" : "title display-flex"}>
          <img src={AssetHelper.imagePath(meta.owner.avatar.hash, ImageVariants.THUMBNAIL)} />
          <span className="presentationName">{presentationName}</span>
        </div>);
      } else {
        contentTitle = (<div className={isIE11 ? "title" : "title display-flex"}>
          <span className="presentationName">{presentationName}</span>
        </div>);
      }

      if (presentation.scenes && presentation.scenes.length > 1 ) {
        mapsContainerVisible = !splashScreenVisible;
        autoplayVisible = true;


        scenes = presentation.scenes && presentation.scenes.map(scene => {
          let sphereResource = presentation.resources.spheres.find((res) => {
              return res.id == scene.spheres[0].resourceId;
            }),
            videoSphericalResource = presentation.resources.videosSpherical.find((res) => {
              return res.id == scene.spheres[0].videoResourceId;
            }),
            resource = sphereResource ? sphereResource : videoSphericalResource,
            styles = resource ? {
              background: `url(${AssetHelper.imagePath(resource.hash, ImageVariants.THUMBNAIL)})`,
              backgroundSize: '400% 400%',
              backgroundPosition: 'center center'
            } : {},
            className = Classnames({
              'scene': true,
              'isSelected': scene.id === sceneId,
            });

          return (
            <div
              className={className}
              key={scene.id}
              onClick={() => { this.changeScene(scene.id); }}
            >
              <div className="scene-background" style={styles}></div>
              <div className="scene-name">
                <span>{scene.name}</span>
              </div>
            </div>
          );});
      }
    }

    const alias = presentationMeta && presentationMeta.alias ? `-${presentationMeta.alias}` : '';
    const canonicalPresentationLink = `${window.location.origin}/p/${presentationHash}${alias}`;

    return (
      <div>
        <MetaTags>
          <link rel="canonical" href={canonicalPresentationLink} />
        </MetaTags>
        {passwordRequested ?
          <PrivtePresentationOverlay fetchPresentation={fetchPresentation} hash={presentationHash} error={error}/> :
          <div>
            {!this.state.splashScreenVisible && this.state.mousePngVisibility &&
          <div className="mouse-move-container" onClick={() => this.setMousePngVisibility(false)}>
            <img className="mouse-move-png" src={AssetHelper.staticPath('/static/home/embed/embed_mouse_move.png')} />
            <div>{t('embed.dragAndDropAction')}</div>
          </div>}
            <div className={"embed" + (this.state.fullscreenActive ? ' fs-js' : '')}>
              {splashScreenVisible && <SplashPage presentation={presentation} onClick={this.loadEmbed}/>}
              {!this.state.splashScreenVisible && <div className={"overlay" + (this.state.mapsVisible || this.state.infoVisible ? ' overlay--opened' : '')}>
              <div className="overlay__header header1 embed">
                {meta && meta.logotype && meta.logotype.hash ?
                    (<div className="avatar" style={{ backgroundImage: `url(${AssetHelper.imagePath(meta.logotype.hash, ImageVariants.LOGOTYPE)})`}} />)
                  :
                  <React.Fragment>
                    {meta && meta.owner.avatar && meta.owner.avatar.hash &&
                        (<div className="avatar" style={{ backgroundImage: `url(${AssetHelper.imagePath(meta.owner.avatar.hash, ImageVariants.LOGOTYPE)})`}} />)
                    }
                  </React.Fragment>
                }
                <div className="controls">
                  {mapsContainerVisible && (
                    <div className="controls-centered">
                      <span className="control arrow-left" onClick={this.prevScene}>
                        <IconArrow />
                      </span>
                      <span className={`control scrollButton${this.state.mapsVisible ? ' inverse' : ''}`} onClick={this.toggleContent}>
                        <IconArrow />
                      </span>
                      <span className="control arrow-right" onClick={this.nextScene}>
                        <IconArrow />
                      </span>
                    </div>
                  )}
                </div>
              </div>
                <div className="overlay__header header2 embed">
                  <div className="content">
                    <div className="presentation-name-container">
                      <span className="presentation-name">{presentationName}</span>
                    </div>
                  </div>
                  <div className="controls">
                    <div className="flex-container">
                      <div className="slider-container">
                        <IconRemove
                          className="slider-icons"
                          onClick={() => {
                            if (zoom > 1) {
                              this.zoomOut();
                              this.setState({
                                ...this.state,
                                zoom: zoom - 1,
                              });
                            }
                          }}
                        />
                        <Slider
                          min={1}
                          max={16}
                          defaultValue={zoom}
                          value={zoom}
                          className="slider"
                          onChange={value => {
                            this.setState({
                              ...this.state,
                              zoom: value,
                            });
                            this.changeZoom(value);
                          }}
                        />
                        <IconAdd
                          className="slider-icons"
                          onClick={() => {
                            if (zoom < 16) {
                              this.zoomIn();
                              this.setState({
                                ...this.state,
                                zoom: zoom + 1,
                              });
                            }
                          }}
                        />
                      </div>
                    </div>
                    <div>
                      {this.state.autoplay ? (
                        <IconPause className="imageIcon" onClick={this.toggleAutoplay} />
                      ) : (
                        <IconAutoplay className="imageIcon" onClick={this.toggleAutoplay} />
                      )}
                      <IconVr className="imageIcon" onClick={this.toggleQRModal} />
                      <IconFullscreen className="imageIcon" onClick={this.toggleFullscreen} />
                      <IconInfo className="imageIcon" onClick={this.toggleInfo} />
                      {menuButtonVisible && <IconLayers className="imageIcon" onClick={this.toggleSpaceplansModal} />}
                      {isGyroscopeEnabled !== null && (
                        <React.Fragment>
                          {isGyroscopeEnabled
                            ? (
                              <IconGyroscope className="imageIcon" onClick={this.disableGyroscope} />
                            ) : (
                              <IconGyroscopeOff className="imageIcon" onClick={this.enableGyroscope} />
                            )}
                        </React.Fragment>
                      )}
                    </div>
                  </div>
                </div>
                {this.state.infoVisible &&
                  <div>
                    <div className="owner-info">
                      <img
                        width={50}
                        height={50}
                        className="owner-avatar"
                        src={meta && meta.owner && meta.owner.avatar ?
                          AssetHelper.imagePath(meta.owner.avatar.hash, ImageVariants.THUMBNAIL)
                          : "/static/home/base-avatar.png"
                        }
                      />
                      <div className="description-box">
                        <div>{meta.owner.name || ''}</div>
                        <div className="description-text">{meta.owner.description || ''}</div>
                      </div>
                    </div>
                    <div className="details-box">
                      <div className="details-content">
                        <IconViews />
                        <span>{meta && meta.viewCount}</span>
                      </div>
                      <div className="details-content">{publicationDate}</div>
                      <div className="details-content">{meta.owner.name}</div>
                    </div>
                  </div>
                }
                {mapsContainerVisible && this.state.mapsVisible &&
                  <OwlCarousel
                    className="scenes-container"
                    options={{
                      autoWidth: true,
                      nav: true,
                      startPosition: this.state.startPosition,
                      itemElement: 'span'
                    }}
                    events={{
                      onDragged: this.updateCarouselPosition,
                      onTranslated: this.updateCarouselPosition
                    }}
                  >
                    {scenes}
                  </OwlCarousel>
                }
              </div>}
              <VRModal opened={this.state.VRModalOpened} requestClose={this.toggleQRModal} shareUrl={shareUrl}></VRModal>
            </div>
            {spaceplansOpened &&
            <SpaceplansModal
              key={this.state.spaceplansKey}
              refreshComponent={() => {
                this.toggleSpaceplansKey();
              }}
              player={this.player}
              toggleSpaceplansModal={this.toggleSpaceplansModal}
              currentSpaceplan={currentSpaceplan}
              setPlanIndex={this.setPlanIndex}
              presentation={presentation} />
            }
          </div>
        }
      </div>
    );
  }
}

function PlayerEmbed(props) {
  return (
    <PlayerContext.Consumer>
      {player => (
        <Embed
          {...props}
          player={player.player}
        />
      )}
    </PlayerContext.Consumer>
  );
}

export default translate('translations')(PlayerEmbed);
